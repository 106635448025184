import request from '@/utils/request'

export function roleDetail (id) {
  return request({
    url: '/api/back/employmentUser/role/' + id,
    method: 'get'
  })
}

export function roleAdd (data) {
  return request({
    url: '/api/enterprise/role',
    method: 'post',
    data
  })
}

export function roleEdit (data) {
  return request({
    url: '/api/enterprise/role',
    method: 'put',
    data
  })
}

export function roleDelete (params) {
  return request({
    url: '/api/enterprise/role',
    method: 'delete',
    params
  })
}

export function rolePage (params) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/roleList',
    method: 'get',
    params
  })
}

/**
 * 查询当前角色级别下的角色
 * @param {*} level
 * @returns
 */
export function roleByLevel (level) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/role',
    method: 'get',
    params: {
      level
    }
  })
}

export function queryEnterpriseRole (level) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/queryEnterpriseRole',
    method: 'get',
    params: {
      level
    }
  })
}

export default { rolePage, roleAdd, roleEdit, roleDelete, roleDetail }
