<template>
  <div class="user__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>账号管理
    </div>
    <div class="content">
      <accountForm ref="searchForm" @refresh="$refs.table.initPage()" />
      <div class="">
        <a-button type="primary" class="account_btn" @click="handleAdd" v-perms="'add'">新增账户</a-button>
        <p-table
          ref="table"
          row-key="userId"
          :columns="columns"
          :sourceData="getDataApi"
          :scorll="{y: 600}"
          extraHeight="420"
        >
          <template slot="operation" slot-scope="text, record">
            <a-button type="link" @click="handleUpdate(record)" v-if="currentlevel < record.level || currentlevel == 1" v-perms="'update'">编辑</a-button>
            <a-button type="link" v-if="currentlevel < record.level || currentlevel == 1" @click="handleResetPassWord(record)" v-perms="'reset-pass'">密码重置</a-button>
            <a-popconfirm
              title="确认删除账户吗？"
              @confirm="handleDelete(record)"
              ok-text="是"
              cancel-text="否"
              v-if="currentlevel < record.level"
              v-perms="'delete'"
            >
              <a-button type="link">删除账户</a-button>
            </a-popconfirm>
          </template>
        </p-table>
      </div>
    </div>
    <dialogForm ref="dialogForm" @success="$refs.table.refresh()" />
  </div>
</template>

<script>
import { enterpriseSalaryManagementlist, deleteById } from '@/api/system'
import accountForm from './account-form.vue'
import dialogForm from './form.vue'
import { roleByLevel } from '@/api/employmentUser/role'
import { getInfo } from '@/api/login'
const columns = [
  {
    title: '姓名',
    dataIndex: 'realName',
    key: 'realName'
  },
  {
    title: '登录账号',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true
  },
  {
    title: '角色',
    dataIndex: 'role',
    key: 'role',
    ellipsis: true
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 300,
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'AccountPage',
  components: {
    accountForm,
    dialogForm
  },
  data () {
    return {
      columns,
      currentlevel: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getInfo().then(res => {
        this.currentlevel = res.data.roleInfo[0].level
      })
    },
    getDataApi (params) {
      const query = this.$refs.searchForm.getQuery()
      return enterpriseSalaryManagementlist({
        ...query,
        ...params
      })
    },
    handleAdd () {
      this.$refs.dialogForm.createModel()
    },
    handleUpdate (row) {
      this.$refs.dialogForm.editModel(row)
    },
    handleResetPassWord (row) {
      this.$refs.dialogForm.resetPassword(row)
    },
    handleDelete (row) {
      deleteById(row.userId).then(res => {
        this.$message.success('账户删除成功')
        this.$refs.table.initPage()
      })
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('../../materialDemand/order/index');
.user__wrapper{
  height: 100%;
  .content{
    height: calc(100% - 45px);
    padding: 24px;
  }
}
</style>
<style scoped lang="less">
@import url('./index');
</style>
