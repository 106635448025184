<template>
  <a-form-model
    :model="form"
    class="role__form"
    :label-col="{span:5}"
    :wrapper-col="{span: 18}"
    labelAlign="left"
  >
    <a-row>
      <a-col :span="5">
        <a-form-model-item label="角色">
          <a-select placeholder="请选择" v-model="form.roleId" allowClear>
            <a-select-option
              v-for="item in roleList"
              :key="item.roleId"
              :value="item.roleId"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="5">
        <a-form-model-item label="姓名">
          <a-input placeholder="请输入" v-model="form.realName" />
        </a-form-model-item>
      </a-col>
      <a-col :span="5">
        <a-form-model-item label="手机号">
          <a-input placeholder="请输入" v-model="form.phone" />
        </a-form-model-item>
      </a-col>
      <a-col :span="5">
        <a-form-model-item label="登录账号">
          <a-input placeholder="请输入" v-model="form.username" />
        </a-form-model-item>
      </a-col>
      <a-col :span="4">
        <div class="form_btn_box" v-perms="'search'">
          <a-button @click="reset">重置</a-button>
          <a-button style="margin-left: 8px" type="primary" @click="refresh">查询</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { queryEnterpriseRole } from '@/api/employmentUser/role'

export default {
  name: 'ProductHeader',
  data () {
    return {
      form: {},
      roleList: []
    }
  },
  mounted () {
    queryEnterpriseRole().then(res => {
      this.roleList = res.data
    })
  },
  methods: {
    reset () {
      this.$set(this, 'form', {})
    },
    refresh () {
      this.$emit('refresh')
    },
    getQuery () {
      return {
        ...this.form
      }
    }
  }
}
</script>

<style lang="less">
.role__form {
  .form_btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
