<template>
  <a-modal
    :visible="visible"
    :title="title"
    @ok="handleSubmit"
    @cancel="handleClose"
    width="500px"
    :confirmLoading="loading"
  >
    <a-form-model
      :model="form"
      ref="form"
      :rules="rules"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="姓名" prop="realName" v-if="!isPassword">
        <a-input v-model="form.realName" placeholder="姓名" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phone" v-if="!isPassword">
        <a-input v-model="form.phone" placeholder="手机号" />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email" v-if="!isPassword">
        <a-input v-model="form.email" placeholder="邮箱" />
      </a-form-model-item>
      <a-form-model-item label="角色" prop="roleId" v-if="!isPassword && form.level != 1">
        <a-select placeholder="请选择角色" v-model="form.roleId" allowClear>
          <a-select-option
            v-for="item in roleList"
            :key="item.roleId"
            :value="item.roleId"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="登录账号" prop="username" v-if="!isPassword && form.level != 1">
        <a-input v-model="form.username" placeholder="登录账号" />
      </a-form-model-item>
      <a-form-model-item :label="isPassword ? '输入新密码' : '登录密码'" prop="password" v-if="!edit">
        <a-input-password
          v-model="form.password"
          :placeholder="isPassword ? '输入新密码' : '登录密码'"
          allowClear
        />
      </a-form-model-item>
      <a-form-model-item label="确认新密码" prop="passwordAgain" v-if="isPassword">
        <a-input-password v-model="form.passwordAgain" placeholder="确认新密码" allowClear />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { roleByLevel } from '@/api/employmentUser/role'
import { enterpriseSalaryManagementAdd, editEnterpriseSubUser, enterpriseSalaryResetPassword } from '@/api/system'

export default {
  name: 'RoleForm',
  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      loading: false,
      edit: false,
      visible: false,
      form: {},
      roleList: [],
      rules: {
        realName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur'
          },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确的邮箱',
            trigger: 'blur'
          }
        ],
        roleId: [
          {
            required: true,
            message: '请选择角色',
            trigger: 'change'
          }
        ],
        username: [
          {
            required: true,
            message: '请输入登录账号',
            trigger: 'blur'
          },
          {
            max: 12,
            message: '登录账号长度不允许超过12位'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur'
          },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/, message: '密码必须由数字+字母组成且长度为8~12位' }
        ],
        passwordAgain: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur'
          },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/, message: '密码必须由数字+字母组成且长度为8~12位' },
          {
            validator: (rule, value, callback) => {
              if (this.form.password && this.form.password !== value) {
                callback(new Error('两次密码输入不一致，请重新输入'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      roleType: [],
      isPassword: false
    }
  },
  computed: {
    title () {
      if (this.isPassword) {
        return '账号重置'
      }
      return (this.edit ? '编辑' : '新增') + '账户'
    }
  },
  mounted () {
    roleByLevel().then(res => {
      this.roleList = res.data
    })
  },
  methods: {
    createModel () {
      this.$set(this, 'form', {})
      this.visible = true
    },
    editModel (model) {
      this.edit = true
      this.$set(this, 'form', { ...model })
      this.visible = true
    },
    resetPassword (model) {
      this.isPassword = true
      this.$set(this, 'form', {
        username: model.username
      })
      this.visible = true
    },
    handleSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let submit
          if (this.isPassword) {
            submit = enterpriseSalaryResetPassword
          } else {
            submit = this.edit ? editEnterpriseSubUser : enterpriseSalaryManagementAdd
          }
          this.loading = true
          submit(this.form)
            .then(res => {
              this.$emit('success')
              this.$message.success(`${this.title}成功`)
              this.handleClose()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleClose () {
      this.visible = false
      this.isPassword = false
      this.edit = false
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped>
</style>
