import request from '@/utils/request'

// 角色
export function enterpriseSalaryRole (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/queryByAdminUserId',
    method: 'get'
  })
}
// 账号管理列表
export function enterpriseSalaryManagementlist (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/queryListByCondition',
    method: 'get',
    params: parameter
  })
}
// 新增用户
export function enterpriseSalaryManagementAdd (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController',
    method: 'post',
    data: parameter
  })
}

// 编辑账号
export function editEnterpriseSubUser (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/editEnterpriseSubUser',
    method: 'put',
    data: parameter
  })
}

// 修改密码
export function enterpriseSalaryResetPassword (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/resetPassword',
    method: 'post',
    data: parameter
  })
}

// 修改密码
export function deleteById (userId) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController',
    method: 'delete',
    params: {
      userId
    }
  })
}

// 修改角色权限
export function enterpriseSalaryMenuTree (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/enterpriseClientMenuTree',
    method: 'get'
  })
}
// 启用账号

export function enterpriseSalaryenabled (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSystemManagementController/enabled/`,
    method: 'put',
    data: parameter
  })
}

// 禁用账号
export function enterpriseSalarydisEnabled (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/disEnabled',
    method: 'put',
    data: parameter
  })
}

/**
 * app账号文件file文件解析
 * @param {*} fileModel
 * @returns
 */
export function appParseExcel (fileModel) {
  return request({
    url: '/api/batchRegisterForApp/parseExcel',
    method: 'post',
    data: fileModel
  })
}

/**
 * app账号批量注册
 * @param {*} model
 * @returns
 */
export function batchRegisterForApp (model) {
  return request({
    url: '/api/enterprise/batchRegisterForApp',
    method: 'post',
    data: model
  })
}
